.ventana {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(7, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
}
.ven1 { grid-area: 1 / 1 / 7 / 3; }
.ven2 { grid-area: 7 / 1 / 8 / 2;margin: auto;margin-right: 3%;margin-top: -0.8%;}
.ven3 { grid-area: 7 / 2 / 8 / 3;margin: auto;margin-left: 3%; }
    

.rol {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 10% repeat(9, auto);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
    .div-rol1 { grid-area: 1 / 1 / 2 / 3; }
    .div-rol2 { grid-area: 2 / 1 / 3 / 6; }
    .div-rol3 { grid-area: 3 / 1 / 4 / 2; }
    .div-rol4 { grid-area: 3 / 2 / 4 / 3; }
    .div-rol5 { grid-area: 3 / 3 / 4 / 4; }
    .div-rol6 { grid-area: 3 / 4 / 4 / 5; }
    .div-rol7 { grid-area: 3 / 5 / 4 / 6; }
    .div-rol8 { grid-area: 4 / 1 / 5 / 4; }
    .div-rol9 { grid-area: 4 / 4 / 5 / 6; }
    .div-rol10 { grid-area: 5 / 1 / 6 / 3; }
    .div-rol11 { grid-area: 5 / 3 / 6 / 4; }
    .div-rol12 { grid-area: 5 / 4 / 6 / 6; }
    .div-rol13 { grid-area: 6 / 1 / 7 / 3; }
    .div-rol14 { grid-area: 6 / 3 / 7 / 4; }
    .div-rol15 { grid-area: 6 / 4 / 7 / 6; }
    .div-rol16 { grid-area: 7 / 1 / 8 / 4; }
    .div-rol17 { grid-area: 7 / 4 / 8 / 6; }
    .div-rol18 { grid-area: 8 / 1 / 9 / 4; }
    .div-rol19 { grid-area: 8 / 4 / 9 / 6; }
    .div-rol20 { grid-area: 9 / 4 / 10 / 5; margin: auto;margin-left: 80%;}
    .div-rol21 { grid-area: 9 / 5 / 10 / 6; margin: auto;}