.div-carpetas{
    margin-top: 30px;
    display: inline-flex;
    text-align: center;
    min-width: 90%;
}
.sec-carpetas{
    margin-right: 120px;
    margin-bottom: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: left;
}
.lbl-casos{
    margin-left: 15px;
    padding: 0%;
    font-size: large;
    text-align: left;;
}
.lbl-num-casos{
    margin-left: 15px;
    font-family: 'Franklin Gothic Medium';
    font-size: large;
    
}
.lbl-casos-tabla{
    display: inline-flex;
    align-items: center;
    justify-content: left;
}
.lbl-casos-tabla>*{
    padding: 5px;
}

.div-busqueda{ 
    padding: 30px;
    margin-top: 10px;
    margin-right: 75%;
    display: inline-flex;
}
.div-busqueda> * {
    margin-right: 15px;
}

.icon-button:hover{
    color:white;
}