#div-parent {
    text-align: center;
    margin-top: 5vw;
}

.div-children {
    width: 19vw; /*Esta funcion sirve para ajustar el tamaño de las imagenes */
    height: 40vh;
    margin: 0.4px;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.4px;
}

.div-grandchild p {
    font-family: arial black;
    color: #00203b;
}

.div-children > a {
    text-decoration: none;
    font-family: arial;
    color: #00203b;
}

.div-grandchild:hover {
    filter: invert(17%) sepia(83%) saturate(1250%) hue-rotate(322deg) brightness(87%) contrast(99%);
}

.lnk-style {
    text-align: left;
    padding: 1rem 0;
    text-decoration: none !important;
    color: #00203b;
    display: block;
}

.lnk-style:hover {
    background-color: #771f2a;
    color: white;
}

.lnk-style > * {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
}

.img-iconos {
    width: 20%; 
}

/* Estilos de la alerta Investigador */
.Alert-Investigador {
    font-family: sans-serif;
    text-align: left;
}
