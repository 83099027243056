/*Brandon Hernandez 11/2022*/
.div-parent {
    margin: 20px 30px 80px 30px;
  }
  .div-breadcrumbs {
    margin-top: 20px;
  }
  .div-container {
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: #fafafa;
    display: inline-block;
    min-width: 95%;
  }
  footer {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
  @media only screen and (max-width: 2000px) {
    .div-container{
      width: 97%;
    }
  }
  @media only screen and (max-width: 1600px) {
    .div-container{
      width: 95%;
    }
  }
  @media only screen and (max-width: 1500px) {
    .div-container{
      min-width: 93%;
      max-width: 95%;
    }
  }
  @media only screen and (max-width: 1280px) {
    .div-container{
      min-width: 100%;
    }
  }
  @media only screen and (max-width: 1080px) {
    .div-container{
      min-width: 106%;
    }
  }
  @media only screen and (max-width: 1000px) {
    .div-container{
      min-width: 105%;
    }
  }
  @media only screen and (max-width: 640px) {
    .div-container{
      min-width: 113%;
    }
  }

input::-ms-reveal,
  input::-ms-clear {
  display: none;
}
hr {
  height: 1px;
  background-color: rgb(0, 0, 0);
  width: 100% !important;
  
}
.custom-helpertext:hover {
  color: black !important; /* Cambiar el color deseado al hacer hover */
}
.dictamen-polizas-txf {
  margin-left: 10px !important;
}

