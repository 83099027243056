    .infoase {
        display: grid;
         grid-template-rows:  repeat(9, 80px)  ;
        background-color: #f8f8f9;
        }
        .infoase1{ grid-area: 1 / 1 / 2 / 3;} 
        /* Area superior Informacion de Asegurado */
        .infoase2 { grid-area: 2 / 1 / 2 / 1;} /* Nombre */
        .infoase3 { grid-area: 2 / 2 / 2 / 2; margin-left: -3cm; }/* Fecha */
        .infoase4 { grid-area: 2 / 3 / 2 / 3; margin-left: -4.5cm;}/* Edad */
        .infoase5 { grid-area: 2 / 4 / 2 / 4; margin-left: -1.5cm;}/* Sexo */
        .infoase6 { grid-area: 2 / 5 / 2 / 5; margin-left: -2.5cm;}/* RFC */
        .infoase7 { grid-area: 2 / 6 / 2 / 6; margin-left: -2.5cm;}/* CURP */
        
        .infoase9 { grid-area: 3 / 1 / 3 / 1;margin-top:.5cm;}/* Folio */
        .infoase8 { grid-area: 3 / 2 / 3 / 2;margin-top:.5cm; margin-left: -3cm;}/* F SINIESTRO */
        .infoase11 { grid-area: 3 / 3 / 3 / 3;margin-top:.5cm;margin-left: -4.5cm;}/* Numero Siniestro */
        .infoase12 { grid-area: 3 / 4 / 3 / 4;margin-top:.5cm;margin-left: -1.5cm;}/* Tipo de Reclamacion */
        .infoase26 { grid-area: 3 / 5 / 3 / 5;margin-top:.5cm;margin-left: -2.5cm;}/* Cobertura */
        .infoase25 { grid-area: 3 / 6 / 3 / 6;margin-top:.5cm;margin-left: -2.5cm;}/* Estatus */
/* Area intermedio: Informacion de la poliza */

        .infoase10 { grid-area: 4 / 1 / 5 / 7;}
   
        .infoase13 { grid-area: 6 / 1 / 6 / 2; margin-top: 1.4cm; }
        .infoase14 { grid-area: 6 / 2 / 6 / 2; margin-top: 1.2cm;}
       
    
        /* Area inferior: Contacto y botones */
        .infoase20 { grid-area: 7 / 1 / 7 / 7; }
        .conta { grid-area: 7 / 1 / 7 / 7; margin-top: 1cm;}
        .infoase19 { grid-area: 7 / 2 / 7 / 1; margin-top: 1cm;text-align:center;}
        .infoase17 { grid-area: 7 / 2 / 7 / 3; margin-top: 1cm;text-align:center; }
        .infoase18 { grid-area: 7 / 4 / 7 / 6; margin-top: 1cm; text-align:center; }

        .email { grid-area: 8 / 1 / 8 / 1;margin-top: 1.6cm;  text-align: center;  }
        .tele { grid-area:8 / 2 / 8 / 3; margin-top: 1.6cm; text-align: center;}

        .infoase21 { grid-area: 9 / 7 / 9 / 5; margin-top: 1cm; margin-left: -4cm;}
        .infoase22 { grid-area: 9 / 8 / 9 / 6; margin-top: 1cm;}



/*  */
        
       
        
        hr {
            height: 0.5px;
            background-color: rgb(94, 94, 94);
        }
