@font-face {
  font-family: "Avenir-light";
  src: url("../fonts/Avenir.ttc");
}

@font-face {
  font-family: "Avenir-black";
  src: url("../fonts/Avenir\ Next.ttc");
}

@font-face {
  font-family: "Futura-Medium";
  src: url("../fonts/Futura\ Medium.ttf");
}

/*Titulo de header*/
h1-header {
  color: #fff;
  font-family: Futura-Medium, sans-serif, Helvetica;
  font-size: 30px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Este tipo de letra se utiliza para el titulo de los dialog*/
h1-dialog-title {
  color: #771f2a;
  font-family: Avenir-black, sans-serif, Helvetica;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/* Ingresar en login*/
h2-login-title {
  color: #00203b;
  font-family: Avenir-black, sans-serif, Helvetica;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Descripción del dialog*/
h2-dialog-description {
  color: #000000;
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Breadcrums*/
h2-breadcrumbs-primary {
  color: #00203b;
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Breadcrums página donde se encuentra*/
h3-breadcrumbs-secundary {
  color: #00203b;
  font-family: Avenir-black, sans-serif, Helvetica;
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Crear cuenta*/
h3-login-subtitle {
  color: #00203b;
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 20px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*label de los textfield*/
h4-label {
  color: #000000;
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Titulo Buscar por en el modulo registro*/
h4-search {
  color: #000000;
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 24px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Footer información*/
h5-footer-information {
  color: #fff;
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
  text-align: center;
}

/*Footer información*/
h6-footer-copyright {
  color: #fff;
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 10px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
  text-align: center;
}

h6-user-name {
  color: #fff;
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
  text-align: center;
}

text-box {
  border-color: #aaaaaa;
  background-color: #fff;
  border-width: 1px;
}

button-gray-small {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  color: #fff;
  font-size: 15px;
  text-align: center;
}

button-vino-small {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  color: #fff;
  font-size: 15px;
  text-align: center;
}

button-vino-large {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  color: #fff;
  font-size: 26px;
  text-align: center;
}

link-forgot-password {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  color: #000000;
  font-size: 15px;
  text-align: center;
}

table-header {
  border-color: #aaaaaa;
  background-color: #00203b;
  border-width: 1px;
}

table-header-titles {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 22px;
  color: #fff;
}

table {
  border-color: #aaaaaa;
  background-color: #fff;
  border-width: 1px;
}

/*Encabezado card*/
h3-header-card {
  color: #000000;
  font-family: Avenir-black, sans-serif, Helvetica;
  font-size: 23px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Titulo card*/
h3-title-card {
  color: #000000;
  font-family: Avenir-black, sans-serif, Helvetica;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

h3-subtitle-card {
  color: #000000;
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Brandon - mod investigador*/
folder-titles {
  padding-left: 10px;
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 20px;
  color: #000000;
}

folder-titles-cases {
  padding-left: 10px;
  font-family: Avenir-black, sans-serif, Helvetica;
  font-size: 20px;
  color: #000000;
}

/* Brandon- Menu style*/
.div-children-menu>* {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 29.26px;
  color: #00203b;
}

.div-children-menu a {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 20px;
  padding-left: 5px;
}


/*Modal Admin*/
h11-title-modal-admin {
  font-family: Avenir-black, sans-serif, Helvetica;
  color: #771f2a;
  font-size: 26px;
  text-align: center;
}

h3-medic-icons {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  color: #000000;
  font-size: 22px;
}

h3-contable {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  color: #00203b;
  font-size: 16px;
}

h3-contable-red {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  color: #771f2a;
  font-size: 16px;
}

/*Titulo de cards polizas*/
h1-header-card-polizas {
  color: #fff;
  font-family: Futura-Medium, sans-serif, Helvetica;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Encabezado solo para registro */
h3-header-card-cobertura {
  color: #771f2a;
  font-family: Avenir-black, sans-serif, Helvetica;
  font-size: 23px;
  margin-top: 0px;
  margin-bottom: 0px;
  display: block;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}

/*Íconos dictamen*/
dictamen-icono-carta-off {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  color: #00203b;
  font-size: 16px;
}

dictamen-icono-carta-on {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  color: #771f2a;
  font-size: 16px;
}

/*Letra de botón upload*/
btn-upload {
  font-family: 'Avenir-light', sans-serif, Helvetica;
  font-size: 13px;
  color: #761f2a;
  display: block;
}

/* styles.css */
.no-link-style {
  color: #00203b;
  cursor: pointer;
}

/* Opcional: Establece un nuevo color para los enlaces cuando se pasa el ratón por encima */
.no-link-style:hover {
  color: #761f2a;
}
/*Este tipo de letra se utiliza para el mensaje de token inválido*/
h1-invalid-token {
  color: #771f2a;
  font-family: Avenir-black, sans-serif, Helvetica;
  font-size: 35px;
  margin-top: 40px;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  /*text-overflow: ellipsis; /*El contenido desbordante se sustituye por puntos suspensivos:...*/
  text-transform: none;
}
