/*Brandon Hernandez 11/2022*/
body {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
}
.header {
  position: sticky;
  top: 0;
  z-index: 999;
  height: 100%;
  background-color: white;
}
.div-trapeze1 {
  position: relative;
  float: left;
  left: 30%;
  width: 90%;
  height: 125px;
  transform: skew(-30deg);
  z-index: -1;
}
.div-trapeze2 {
  position: relative;
  float: left;
  width: 45%;
  height: 125px;
  transform: skew(-30deg);
  z-index: 1;
}
.div-rectangle1 {
  text-align: center;
  position: relative;
  float: left;
  left: -150%;
  width: 150%;
  height: 125px;
}
.div-rectangle2 {
  text-align: left;
  position: relative;
  float: left;
  width: 130%;
  left: -30%;
  height: 125px;
}
.div-panel {
  height: 125px;
  width: 100%;
  left: -10%;
}
@media only screen and (min-width: 100px) {
  .div-trapeze1 {
    z-index: 1;
  }
  .div-rectangle1 {
    left: -90%;
    width: 250%;
    height: 125px;
    z-index: -1;
    transform: skew(-30deg);
  }
}
@media only screen and (max-width: 1600px) {
  .logo-qait{
    width: 100%;
  }
  .div-trapeze1 {
    z-index: 1;
  }
  .div-panel{
    width: 100%;
  }
  .div-rectangle2 {
    width: 130%;
  }
}
@media only screen and (max-width: 1500px) {
  .div-panel{
    width: 100%; 
  }
  footer{
    min-width: 100%;
  }
}
@media only screen and (max-width: 1280px) {
  .div-panel{
    width: 150%;
  }
  footer{
    min-width: 108%;
  }
}
@media only screen and (max-width: 1080px) {
  .div-panel{
    width: 203%;
  }
  footer{
    min-width: 117%;
  }
}
@media only screen and (max-width: 640px) {
  .div-panel{
    width: 280%;
  }
  footer{
    min-width: 130%;
  }
}