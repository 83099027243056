
.infoaseguradoss {

display: grid;
grid-template-columns: repeat(4, 1fr) repeat(2, 0.8fr);
grid-template-rows: 0.5fr repeat(2, 0.8fr) 0.01fr 0.2fr repeat(3, 0.5fr) 0.3fr;
grid-column-gap: 10px;
grid-row-gap: 0px;

    /* display: grid;
    grid-template-columns: repeat(1fr, 6) ;
    grid-template-rows: 0.2frrepeat(1fr, 7) 0.1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px; */
    }



.infoaseguradoss1 { grid-area: 1 / 1 / 2 / 3; }
.infoaseguradoss2 { grid-area: 2 / 1 / 3 / 2; }
.infoaseguradoss3 { grid-area: 2 / 2 / 3 / 3; }
.infoaseguradoss4 { grid-area: 2 / 3 / 3 / 4; }
.infoaseguradoss5 { grid-area: 2 / 4 / 3 / 5; }
.infoaseguradoss6 { grid-area: 3 / 1 / 4 / 2; }
.infoaseguradoss7 { grid-area: 3 / 2 / 4 / 3; }
.infoaseguradoss8 { grid-area: 3 / 3 / 4 / 4; }
.infoaseguradoss9 { grid-area: 3 / 4 / 4 / 5; }
.infoaseguradoss10 { grid-area: 2 / 5 / 4 / 6; margin: auto;margin-left: -50%;}
.infoaseguradoss11 { grid-area: 2 / 6 / 4 / 7; margin: auto;margin-left: -20%;}
.infoaseguradoss12 { grid-area: 4 / 1 / 5 / 7; }
.infoaseguradoss13 { grid-area: 5 / 1 / 6 / 3; }
.infoaseguradoss14 { grid-area: 6 / 1 / 7 / 3; }
.infoaseguradoss15 { grid-area: 6 / 3 / 7 / 4; }
.infoaseguradoss16 { grid-area: 6 / 4 / 7 / 6; }
.infoaseguradoss17 { grid-area: 7 / 1 / 8 / 2; }
.infoaseguradoss18 { grid-area: 7 / 2 / 8 / 3; }
.infoaseguradoss19 { grid-area: 7 / 3 / 8 / 4; }
.infoaseguradoss20 { grid-area: 7 / 4 / 8 / 6; }
.infoaseguradoss21 { grid-area: 6 / 5 / 8 / 7; margin: auto;margin-left: 50%;}
.infoaseguradoss22 { grid-area: 8 / 1 / 9 / 7; }
.infoaseguradoss23 { grid-area: 9 / 1 / 10 / 3; }
.infoaseguradoss24 { grid-area: 9 / 6 / 10 / 7; }
  
   

    hr {
        height: 1px;
        background-color: rgb(0, 0, 0);
        width: 100% !important;
        
    }