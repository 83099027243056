

.parentformat {
    display: grid;
grid-template-columns: 0.5fr 0.8fr 0.5fr;
grid-template-rows: repeat(2, 0.5fr);
grid-column-gap: 0px;
grid-row-gap: 0px;
    }



.dateformat1 { grid-area: 1 / 1 / 3 / 2; margin: auto;}
.dateformat2 { grid-area: 1 / 2 / 2 / 3; margin: auto;}
.dateformat3 { grid-area: 2 / 2 / 3 / 3; margin: auto;}
.dateformat4 { grid-area: 1 / 3 / 3 / 4; margin: auto;}


.parentdias {
    display: grid;
    grid-template-columns: repeat(4, 0.5fr);
    grid-template-rows: repeat(2, 1fr) 0.1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    }
    
.dias1 { grid-area: 1 / 1 / 2 / 2; }
.dias2 { grid-area: 2 / 1 / 3 / 2; }
.dias3 { grid-area: 1 / 2 / 2 / 3; }
.dias4 { grid-area: 2 / 2 / 3 / 3; }
.dias5 { grid-area: 1 / 3 / 2 / 4; }
.dias6 { grid-area: 2 / 3 / 3 / 4; }
.dias7 { grid-area: 1 / 4 / 2 / 5; }
.dias8 { grid-area: 2 / 4 / 3 / 5; }
.dias9 { grid-area: 2 / 4 / 4 / 5;margin: auto; }


#div-parent{
    text-align: center ;
    display: inline-block ;
    margin-top: 100px;
}
.div-children-menu{
    width: 300px;
    height: 400px;
    padding-right: 40px;
    padding-bottom: 100px;
    cursor: pointer; 
    height: 100%;
    display: inline-table;
}
.div-grandchild p {
    font-family: arial black;
    color: #00203b;
}
.lnk-style{
    text-align: left;
    padding: 10px 0 10px 0;
    text-decoration: none !important;
    color: #00203b;
}

.lnk-style >* {
    display:flex;
    justify-content: left;
    align-items: center;
    cursor: pointer; 
    padding-left: 5px;
    padding-right: 5px;
    
}
.img-iconos{
    padding: 0;
    margin: 0;
    width: 60%;
}
.div-children-menu:hover>* {
    color:#771f2a;
}




.parent {
    display: grid;
grid-template-columns: repeat(2, 0.5fr);
grid-template-rows: 0.2fr 1fr 0.5fr;
grid-column-gap: 0px;
grid-row-gap: 0px;
    }
    
    .div1 { grid-area: 2 / 1 / 4 / 2; }
    .div2 { grid-area: 1 / 1 / 2 / 3; }
    .div3 { grid-area: 2 / 2 / 3 / 3; }
    .div4 { grid-area: 3 / 2 / 4 / 3; }