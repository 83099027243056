.btn-detalle-tabla{
    background-color: #fafafa;
}
.btn-detalle-tabla:hover{
    background-color: #cfc0c0;
}
.div-cards * {
    margin-bottom: 20px !important;
}
.div-container-dictamenxfolio{
    position: sticky;
    margin: 0px 0px 0px 40px;
    padding-left:20px;
    border-left: 2px solid #A9A9A9;
}
.div-dictamen-folio{
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0px 0px 0px 40px;
    padding-right:20px;
    padding-left:20px;
    border-left: 2px solid #A9A9A9;
    min-height: 500px;
    max-height:500px;
}
.div-dictamen-folio hr{
    margin-top: 20px !important;
}
.tbl-dictamen-polizas th, .tbl-dictamen-polizas td{
    border: 2px solid #00203b;
    padding: 10px;
    max-width: 230px;
    height: 50px;
}

.div-tabla-dictamen tr:nth-child(even) {
    background-color:  #00203b33;
}
.div-tabla-dictamen tr:last-child>td {
    background-color: #fafafa;
    border: 2px solid #fafafa;
    padding: 10px;
    max-width: 230px;
    height: 50px;
}
.div-tabla-dictamen td{
    border: 2px solid #00203b80;
}

.div-tabla-dictamen table{
    background-color: #fafafa;
}
.div-tabla-dictamen th{
    background-color:#00203b;
    text-align: left;
    max-width: 230px;
}
.div-tabla-dictamen{
    display: flex;
    flex-wrap: nowrap;
}
.div-dictamen-folioX{
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 500px;
    max-height: 500px;
}
.ul-dictamen-reservas{
    padding:0 !important;
    list-style-type: none;
}
.ul-dictamen-reservas a{
    padding:5px !important;
    text-decoration: none;
    color: inherit;
}
.ul-dictamen-reservas li{
    margin-bottom: 5px;
}
.ul-dictamen-reservas > ul{
    padding: 10px !important;
}
.dictamen-reservas-items >div:hover{
    background-color: #ededed;
}
.img-iconos-dictamen{
    padding: 0;
    margin: 0;
    width: 70px;
    height: 70px;
}
.grid-item-generar-carta{
    display: inline-flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
   
}
.grid-item-generar-carta>*:not(:first-child){
    padding-left: 10px;
    margin-right: 100px;
}
.grid-container-generar-carta{
    margin: 50px 0px 50px;
}
