.ventstanasa {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 0.1fr repeat(3, 0.5fr) repeat(2, 1fr) 0.5fr;
    grid-column-gap: 7px;
    grid-row-gap: 0px;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 25px;
    min-width: 95%;
    }
    
    .vents1 { grid-area: 1 / 1 / 2 / 2; }
    .vents2 { grid-area: 2 / 1 / 3 / 2; }
    .vents3 { grid-area: 2 / 2 / 3 / 3; }
    .vents4 { grid-area: 2 / 3 / 3 / 4; }
    .vents5 { grid-area: 3 / 1 / 4 / 2; }
    .vents6 { grid-area: 3 / 2 / 4 / 3; }
    .vents7 { grid-area: 3 / 3 / 4 / 4; }
    .vents8 { grid-area: 4 / 1 / 5 / 4; }
    .vents9 { grid-area: 5 / 1 / 7 / 4; }
    .vents10 { grid-area: 7 / 2 / 8 / 3; }
    .vents11 { grid-area: 7 / 3 / 8 / 4; margin-left: -45%;}