.div-children-comp{
    width: 100px;
    height: 200px;
    margin: 0;
    margin-top: 10px;
    cursor: pointer; 
    height: 100%;
    float:inline-end;
    display: inline-table;
}

.div-grandchild-comp p {
    font-family: arial black;
    color: #00203b;
}

.img-iconos-comp{
    padding: 0;
    margin: 0;
    width: 70px;
    height: 70px;
}

.espacio {
    margin-right: 2rem;
  }
.grid-item-menu{
    display: inline-flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
   
}
.grid-item-menu>*:not(:first-child){
    padding-left: 10px;
    margin-right: 100px;
}
.grid-container-menu-all{
    margin: 50px 0px 50px;
}
.grid-container-menu-movimiento{
    margin: 50px 0px 50px;
}
.grid-item-menu-movimiento{
    display: inline-flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
   
}
.grid-item-menu-movimiento>*:not(:first-child){
    padding-left: 10px;
    margin-right: 10px;
}

.boton-gmc{
    width: auto !important;
    height: auto !important;
    color: blue;
}




