.docu{
    display: grid;
         grid-template-rows:  repeat(9, 90px)  ;
        background-color: #f8f8f9;
}
.tipsi{grid-area: 1 / 1 / 1 / 1;}
.sele{grid-area: 2 / 1 / 2 / 1; margin-top: -1cm;}
.bot{grid-area: 2 / 2 / 1 / 2; margin-left: -28cm; margin-top: 1.8cm;}
.tabla{grid-area: 3 / 1 / 4 / 1;margin-top: -1cm;}
.che1 {grid-area: 9 / 1 / 9 / 1;margin-top: 1cm;}
.che2 {grid-area: 9 / 2 / 9 / 2;margin-top: 1cm; margin-left: -28cm;}
.bot2 {grid-area: 9 / 2 / 9 / 2;margin-top: 2cm; margin-left: -5cm;}
