.parentes {
    display: grid;
    grid-template-columns: 1fr 0.5fr 1fr;
    grid-template-rows: 0.5fr repeat(7, 1fr);
   
    }
    
    .d1 { grid-area: 1 / 1 / 2 / 3; }
    .d2 { grid-area: 2 / 1 / 3 / 3; }
    .d3 { grid-area: 3 / 1 / 4 / 3; }
    .d4 { grid-area: 4 / 1 / 5 / 2; }
    .d5 { grid-area: 4 / 3 / 5 / 4; }
    .d6 { grid-area: 5 / 1 / 6 / 3; }
    .d7 { grid-area: 6 / 1 / 7 / 2; }
    .d8 { grid-area: 7 / 3 / 7 / 3;margin-left: 82%; }
    .d9 { grid-area: 7 / 3 / 7 / 3;margin-left: 62%; }

.par {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 0.5fr repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    }
    .di1 { grid-area: 1 / 1 / 2 / 3; }
    .di2 { grid-area: 2 / 1 / 3 / 4; }
    .di3 { grid-area: 2 / 4 / 3 / 7; }
    .di4 { grid-area: 3 / 1 / 4 / 4; }
    .di5 { grid-area: 3 / 4 / 4 / 7; }
    .di6 { grid-area: 4 / 1 / 5 / 4; }
    .di7 { grid-area: 5 / 4 / 6 / 5; margin-left: 150%;}
    .di8 { grid-area: 5 / 5 / 6 / 6; margin-left: 100%;}
    .di9 { grid-area: 5 / 6 / 6 / 7; margin-left: 50%;}

   
    .parentda {
        display: grid;
grid-template-columns: repeat(4, 1fr);
grid-template-rows: repeat(2, 1fr) 0.5fr;
grid-column-gap: 5px;
grid-row-gap: 5px;
        }
        
        .whats1 { grid-area: 1 / 1 / 2 / 5; }
        .whats2 { grid-area: 2 / 1 / 3 / 2; }
        .whats3 { grid-area: 2 / 2 / 3 / 3; }
        .whats4 { grid-area: 2 / 3 / 3 / 4; }
        .whats5 { grid-area: 3 / 4 / 4 / 5; }
        .whats6 { grid-area: 2 / 4 / 3 / 5; }

    